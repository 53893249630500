export const checkDevice = (device_id) => {
  const parts = device_id.split(":");

  // Extract the number from the second element (index 1)
  const number = parseInt(parts[1], 10);

  // Increment the number by 1
  const incrementedNumber = number + 1;

  // Return the formatted string
  return `Máy ${incrementedNumber}`;
};
