import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../store/authSlice";
import apiService from "../services/apiService";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Card } from "antd";
import Password from "antd/es/input/Password";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async (values) => {
    try {
      console.log(values);
      const data = {
        username: values.username,
        password: values.password,
      };
      const response = await apiService.post("/admin/login", data);

      dispatch(
        login({
          info: response.data.info,
          // token: response.data.access_token,
          token: response.data.access_token,
        })
      );
      navigate("/");
    } catch (error) {
      console.error("Login failed", error);
      setLoading(false);
    }
  };

  return (
    <Card title="Login" style={{ width: 300, margin: "100px auto" }}>
      <Form onFinish={handleLogin}>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Login;
