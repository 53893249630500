import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Input,
  message,
  Row,
  Col,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import apiService from "../services/apiService"; // Adjust the path based on your project structure

// API endpoints
const LIST_CAMERA_URL = "/camerasettings/all";
const ADD_CAMERA_URL = "/camerasettings/";
const UPDATE_CAMERA_URL = (id) => `/camerasettings/${id}`;
const DELETE_CAMERA_URL = (id) => `/camerasettings/${id}`;

const CameraSetting = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    try {
      const response = await apiService.get(LIST_CAMERA_URL);
      setData(response.data); // Assuming the response structure has an `items` array
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: 100, // Assuming the response has a `total` field for total items
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: sorter.order,
      sortField: sorter.field,
    });
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    editForm.setFieldsValue(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await apiService.delete(DELETE_CAMERA_URL(id));
      message.success("Camera deleted successfully");
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to delete camera");
    }
  };

  const handleAdd = async () => {
    try {
      const values = await form.validateFields();
      await apiService.post(ADD_CAMERA_URL, values);
      message.success("Camera added successfully");
      setIsModalVisible(false);
      form.resetFields();
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to add camera");
    }
  };

  const handleUpdate = async () => {
    try {
      const values = await editForm.validateFields();
      await apiService.put(
        UPDATE_CAMERA_URL(currentRecord.camera_name),
        values
      );
      message.success("Camera updated successfully");
      setIsEditModalVisible(false);
      editForm.resetFields();
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to update camera");
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Tên Camera",
      dataIndex: "camera_name",
      key: "camera_name",
    },
    {
      title: "Resolution",
      dataIndex: "resolution",
      key: "resolution",
      width: "40%",
      render: (resolution) => resolution.join(", "),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2>Camera</h2>
        <Button
          type="primary"
          icon={<FileAddOutlined />}
          onClick={() => setIsModalVisible(true)}
        >
          Add
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Add Camera"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleAdd}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="camera_name"
                label="Tên Camera"
                rules={[
                  { required: true, message: "Please input the camera name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Resolution">
                <Form.List name="resolution">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name]}
                            rules={[
                              {
                                required: true,
                                message: "Missing resolution detail",
                              },
                            ]}
                          >
                            <Input placeholder="Resolution" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add resolution
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Edit Camera"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={handleUpdate}
      >
        <Form form={editForm} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="camera_name"
                label="Tên Camera"
                rules={[
                  { required: true, message: "Please input the camera name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Resolution">
                <Form.List name="resolution">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name]}
                            rules={[
                              {
                                required: true,
                                message: "Missing resolution detail",
                              },
                            ]}
                          >
                            <Input placeholder="Resolution" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add resolution
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default CameraSetting;
