import React, { useEffect, useState } from "react";
import { Table, Tag, Button, Space, message } from "antd";
import { EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import apiService from "../services/apiService";
const LIST_APPROVE_ACCOUNTS_URL = "/user/get-all-user-full-info/approve";
// Define columns for the table
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = String(date.getFullYear()); // Get last two digits of the year
  return `${day}-${month}-${year}`;
};
const columns = (handleView, handleDelete) => [
  {
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Phone Number",
    dataIndex: "phonenumber",
    key: "phonenumber",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Industry",
    dataIndex: "major",
    key: "major",
  },
  {
    title: "Region",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Ngày Tạo",
    dataIndex: "created_at",
    key: "created_at",
    render: (created_at) => formatDate(created_at),
  },
  // {
  //   title: "Status",
  //   dataIndex: "status",
  //   key: "status",
  //   render: (status) => (
  //     <Tag color={status === "online" ? "green" : "red"}>
  //       {status.charAt(0).toUpperCase() + status.slice(1)}
  //     </Tag>
  //   ),
  // },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button icon={<EyeOutlined />} onClick={() => handleView(record)}>
          View
        </Button>
        <Button
          icon={<DeleteOutlined />}
          danger
          onClick={() => handleDelete(record.key)}
        >
          Delete
        </Button>
      </Space>
    ),
  },
];

// Function to generate mock data

const UserList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 100, // Total number of records for pagination
    },
  });

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    try {
      const response = await apiService.get(LIST_APPROVE_ACCOUNTS_URL, {
        params: {
          skip: (current - 1) * pageSize,
          limit: pageSize,
        },
      });
      console.log(response.data);
      setData(response.data.elements); // Assuming the response structure has an `items` array
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: response.data.count, // Assuming the response has a `total` field for total items
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination.pageSize) {
      setData([]);
    }
  };

  const handleView = (record) => {
    console.log("View:", record);
    // Add your view logic here
    navigate(`/view/${record.id}`);
  };

  const handleDelete = (key) => {
    setData(data.filter((item) => item.key !== key));
  };

  return (
    <div>
      <h2>User List</h2>
      <Table
        columns={columns(handleView, handleDelete)}
        rowKey={(record) => record.key}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default UserList;
