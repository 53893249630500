import React, { useEffect, useState } from "react";
import { Table, Button, Space, message, Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import axios from "axios";
import apiService from "../services/apiService";

// API endpoints
const LIST_PENDING_ACCOUNTS_URL = "/user/get-all-users/pending";
const APPROVE_ACCOUNT_URL = "/user/approve-user";
const DECLINE_ACCOUNT_URL = (id) =>
  `http://localhost:8000/api/v1/user/decline/${id}`;

// Define columns for the table
const columns = (handleApprove, handleDecline) => [
  {
    title: "Full Name",
    dataIndex: "fullname",
    key: "fullname",
  },
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
  },
  {
    title: "Phone Number",
    dataIndex: "phonenumber",
    key: "phonenumber",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Industry",
    dataIndex: "major",
    key: "major",
  },
  {
    title: "Region",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Space size="middle">
        <Button
          icon={<CheckOutlined />}
          type="primary"
          onClick={() => handleApprove(record)}
        >
          Approve
        </Button>
        <Button
          icon={<CloseOutlined />}
          danger
          onClick={() => handleDecline(record.id)}
        >
          Decline
        </Button>
      </Space>
    ),
  },
];

const PendingAccounts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 100, // Total number of records for pagination
    },
  });

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    try {
      const response = await apiService.get(LIST_PENDING_ACCOUNTS_URL, {
        params: {
          skip: (current - 1) * pageSize,
          limit: pageSize,
        },
      });
      console.log(response);
      setData(response.data); // Assuming the response structure has an `items` array
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: response.data.total, // Assuming the response has a `total` field for total items
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });

    if (pagination.pageSize !== tableParams.pagination.pageSize) {
      setData([]);
    }
  };

  const handleApprove = (record) => {
    console.log(record);
    const data = {
      fullname: record.fullname,
      username: record.username,
      password: "....",
      email: record.email,
      address: record.address,
      phonenumber: record.phonenumber,
      major: record.major,
      status: "pending",
      role: 0,
    };
    Modal.confirm({
      title: "Confirm Approval",
      content: `Are you sure you want to approve the account for ${record.fullname}?`,
      onOk: async () => {
        try {
          await apiService.put(APPROVE_ACCOUNT_URL, data);
          message.success("Account approved successfully");
          fetchData(
            tableParams.pagination.current,
            tableParams.pagination.pageSize
          );
        } catch (error) {
          message.error("Failed to approve account");
        }
      },
    });
  };

  const handleDecline = async (id) => {
    try {
      await axios.post(DECLINE_ACCOUNT_URL(id));
      message.success("Account declined successfully");
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to decline account");
    }
  };

  return (
    <div>
      <h2>Pending Accounts</h2>
      <Table
        columns={columns(handleApprove, handleDecline)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default PendingAccounts;
