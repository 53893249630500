import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    token: "",
    info: [],
  },
  reducers: {
    login: (state, action) => {
      console.log(action.payload);
      state.isLoggedIn = true;
      state.info = action.payload.info;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.info = [];
      state.token = "";
    },
    setInfo: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { login, logout, setInfo } = authSlice.actions;
export default authSlice.reducer;
