import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Space, Modal, Form, Input, message } from "antd";
import { v4 as uuidv4 } from "uuid";
import apiService from "../services/apiService"; // Adjust the path based on your project structure
import "./styles/BlackList.css";
import {
  FileAddOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

// API endpoints
const LIST_BLACKLISTS_URL = "/blacklists/";
const ADD_BLACKLIST_URL = "/blacklist/";
const GET_TOTAL_ITEMS_BLACKLITS = "/admin/count?table=blacklist";
const UPDATE_BLACKLIST_URL = (id) => `/blacklist/${id}`;

const Blacklist = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 100, // Total number of records for pagination
    },
  });

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    try {
      const totalItems = await apiService.get(GET_TOTAL_ITEMS_BLACKLITS);

      const response = await apiService.get(LIST_BLACKLISTS_URL, {
        params: {
          skip: (current - 1) * pageSize,
          limit: pageSize,
        },
      });

      setData(response.data.elements); // Assuming the response structure has an `items` array
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: totalItems.data.count, // Assuming the response has a `total` field for total items
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination.pageSize) {
      setData([]);
    }
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    editForm.setFieldsValue(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await apiService.delete(`/blacklist/${id}`);
      message.success("Blacklist entry deleted successfully");
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to delete blacklist entry");
    }
  };

  const handleAdd = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      if (!values.desc) {
        values.desc = " "; // Set desc to " " if it's undefined or not present
      }
      await apiService.post(ADD_BLACKLIST_URL, values);
      message.success("Blacklist entry added successfully");
      setIsAddModalVisible(false);
      form.resetFields();
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to add blacklist entry");
    }
  };

  const handleUpdate = async () => {
    try {
      const values = await editForm.validateFields();
      await apiService.put(UPDATE_BLACKLIST_URL(currentRecord.id), values);
      message.success("Blacklist entry updated successfully");
      setIsEditModalVisible(false);
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to update blacklist entry");
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Sàn",
      dataIndex: "platform",
      key: "platform",
      render: (text) => <Tag color="blue">{text}</Tag>,
    },
    {
      title: "Link khách hàng",
      dataIndex: "link",
      key: "link",
    },
    {
      title: "Lý do",
      dataIndex: "reason",
      key: "reason",
      render: (text) => {
        let color = "geekblue";
        if (text === "Trả hàng") {
          color = "volcano";
        } else if (text === "Bomb hàng") {
          color = "red";
        } else if (text === "Tráo hàng") {
          color = "gold";
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="header-blacklist">
        <h2>Black list</h2>
        <Button
          type="primary"
          icon={<FileAddOutlined />}
          onClick={() => setIsAddModalVisible(true)}
        >
          Add
        </Button>
      </div>

      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Add Blacklist Entry"
        visible={isAddModalVisible}
        onCancel={() => setIsAddModalVisible(false)}
        onOk={handleAdd}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="platform"
            label="Sàn"
            rules={[{ required: true, message: "Please input the platform!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link khách hàng"
            rules={[
              { required: true, message: "Please input the customer link!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reason"
            label="Lý do"
            rules={[{ required: true, message: "Please input the reason!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="desc" label="Ghi chú">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Blacklist Entry"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={handleUpdate}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="platform"
            label="Sàn"
            rules={[{ required: true, message: "Please input the platform!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="link"
            label="Link khách hàng"
            rules={[
              { required: true, message: "Please input the customer link!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="reason"
            label="Lý do"
            rules={[{ required: true, message: "Please input the reason!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="desc" label="Ghi chú">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Blacklist;
