import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Input,
  message,
  InputNumber,
  Row,
  Col,
  Select,
  Tag,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import apiService from "../services/apiService"; // Adjust the path based on your project structure

// API endpoints
const LIST_PACKAGES_URL = (status) => `/packages/get-all-packages/${status}`;
const ADD_PACKAGE_URL = "/packages/create-package";
const UPDATE_PACKAGE_URL = (id) => `/packages/${id}`;

const Package = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [form] = Form.useForm();
  const [view, setView] = useState("all");
  const [editForm] = Form.useForm();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 100, // Total number of records for pagination
    },
  });

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    try {
      const response = await apiService.get(LIST_PACKAGES_URL(view), {
        params: {
          skip: (current - 1) * pageSize,
          limit: pageSize,
        },
      });
      console.log(response.data);
      setData(response.data.elements); // Assuming the response structure has an `items` array
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: response.data.count, // Assuming the response has a `total` field for total items
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, view]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: sorter.order,
      sortField: sorter.field,
    });
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    console.log(record);
    editForm.setFieldsValue(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      await apiService.delete(UPDATE_PACKAGE_URL(id));
      message.success("Package deleted successfully");
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to delete package");
    }
  };

  const handleAdd = async () => {
    try {
      const values = await form.validateFields();
      console.log(values);
      await apiService.post(ADD_PACKAGE_URL, values);
      message.success("Package added successfully");
      setIsModalVisible(false);
      form.resetFields();
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to add package");
    }
  };

  const handleUpdate = async () => {
    try {
      const values = await editForm.validateFields();
      console.log(values);
      console.log(currentRecord.id);
      console.log("-------------------------");
      await apiService.put(UPDATE_PACKAGE_URL(currentRecord.id), values);
      message.success("Package updated successfully");
      setIsEditModalVisible(false);
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to update package");
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
      sorter: (a, b) => a.duration - b.duration,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      sorter: (a, b) => a.cost - b.cost,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      width: "40%",
      render: (details) => details.join(", "),
    },
    {
      title: "Status",
      dataIndex: "is_status",
      key: "is_status",
      width: "10%",
      render: (is_status) => (
        <Tag
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          color={is_status === 0 ? "red" : "green"}
        >
          {is_status === 0 ? "Ẩn" : "Hiển Thị"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div>
          <h2>Gói</h2>
          <Select
            value={view}
            onChange={(value) => setView(value)}
            style={{ marginBottom: 16 }}
          >
            <Select.Option value="all">Tất cả</Select.Option>
            <Select.Option value="hide">Ẩn</Select.Option>
            <Select.Option value="show">Hiển Thị</Select.Option>
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Add
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Add Package"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleAdd}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[
                  { required: true, message: "Please input the duration!" },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="cost"
                label="Cost"
                rules={[{ required: true, message: "Please input the cost!" }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Please input the description!" },
                ]}
              >
                <Input.TextArea showCount maxLength={100} rows={4} />
              </Form.Item>
              <Form.Item
                name="is_status"
                label="Trạng thái"
                rules={[
                  { required: true, message: "Please select the status!" },
                ]}
              >
                <Select placeholder="Select status" style={{ width: "100%" }}>
                  <Select.Option value={1}>Hiển thị</Select.Option>
                  <Select.Option value={0}>Ẩn</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Details">
                <Form.List name="details">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name]}
                            rules={[
                              {
                                required: true,
                                message: "Missing detail",
                              },
                            ]}
                          >
                            <Input placeholder="Detail" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add detail
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Modal
        title="Edit Package"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={handleUpdate}
      >
        <Form form={editForm} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="duration"
                label="Duration"
                rules={[
                  { required: true, message: "Please input the duration!" },
                ]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="cost"
                label="Cost"
                rules={[{ required: true, message: "Please input the cost!" }]}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                rules={[
                  { required: true, message: "Please input the description!" },
                ]}
              >
                <Input.TextArea showCount maxLength={100} rows={4} />
              </Form.Item>
              <Form.Item
                name="is_status"
                label="Trạng thái"
                rules={[
                  { required: true, message: "Please select the status!" },
                ]}
              >
                <Select placeholder="Select status" style={{ width: "100%" }}>
                  <Select.Option value={1}>Hiển thị</Select.Option>
                  <Select.Option value={0}>Ẩn</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Details">
                <Form.List name="details">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name]}
                            rules={[
                              {
                                required: true,
                                message: "Missing detail",
                              },
                            ]}
                          >
                            <Input placeholder="Detail" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Add detail
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default Package;
