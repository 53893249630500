import React, { useEffect, useState } from "react";
import { Card, List, Row, Col, Statistic, Tag, Table, message } from "antd";
import { Bar, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import apiService from "../services/apiService";
const GET_TOTAL_USER = "/statistic/count-user";
const GET_TOTAL_ONLINE = "/statistic/count-online";
const GET_MAJOR = "/statistic/get-major";
const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [recentBlacklist, setRecentBlacklist] = useState([]);
  const [mostBusiness, setMostBusiness] = useState([]);
  const [mostRegion, setMostRegion] = useState([]);

  useEffect(() => {
    // Mock data for demonstration
    const fetchData = async () => {
      try {
        const response = await apiService.get(GET_TOTAL_USER);

        setTotalUsers(response.data); // Assuming the response structure has an `items` array
      } catch (error) {
        message.error("Failed to fetch data");
      }
      try {
        const response = await apiService.get(GET_TOTAL_ONLINE);

        setActiveUsers(response.data); // Assuming the response structure has an `items` array
      } catch (error) {
        message.error("Failed to fetch data");
      }
      try {
        const response = await apiService.get(GET_MAJOR);

        setMostBusiness(response.data); // Assuming the response structure has an `items` array
      } catch (error) {
        message.error("Failed to fetch data");
      }
    };
    fetchData();
  }, []);
  const blacklistColumns = [
    {
      title: "STT",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Sàn",
      dataIndex: "san",
      key: "san",
    },
    {
      title: "Link khách hàng",
      dataIndex: "link",
      key: "link",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Lý do",
      dataIndex: "reason",
      key: "reason",
      render: (text) => {
        let color = "geekblue";
        if (text === "Trả hàng") color = "volcano";
        if (text === "Bomb hàng") color = "red";
        if (text === "Tráo hàng") color = "gold";
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
    },
  ];
  const businessData = {
    labels: mostBusiness.map((item) => item.major),
    datasets: [
      {
        label: "Most Business",
        data: mostBusiness.map((item) => item.count),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  return (
    <div
      style={{
        height: "100%",
        padding: "24px",
        background: "#f0f2f5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>Dashboard</h2>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <Row
          gutter={[16, 16]}
          justify="space-around"
          align="middle"
          style={{ flex: 1 }}
        >
          <Col span={8}>
            <Row gutter={[16, 16]} style={{ height: "100%" }}>
              <Col span={12}>
                <Card
                  bordered={false}
                  style={{ background: "#ffffff", height: "100%" }}
                >
                  <Statistic
                    title="Total Users Registered"
                    value={totalUsers}
                  />
                </Card>
              </Col>

              <Col span={12}>
                <Card
                  bordered={false}
                  style={{ background: "#ffffff", height: "100%" }}
                >
                  <Statistic title="Total Active Users" value={activeUsers} />
                </Card>
              </Col>
              <Col span={24} style={{ height: "calc(100% - 108px)" }}></Col>
            </Row>
          </Col>
          <Col span={16}>
            <Card
              title="5 Most Business"
              bordered={false}
              style={{ background: "#ffffff", height: "100%" }}
            >
              <Bar
                data={businessData}
                options={{
                  maintainAspectRatio: false,
                }}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
