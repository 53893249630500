import React, { useState } from "react";
import {
  AuditOutlined,
  BarcodeOutlined,
  DashboardOutlined,
  DollarOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  VideoCameraAddOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Dropdown,
  Layout,
  Menu,
  Modal,
  theme,
  Form,
  Input,
} from "antd";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import Home from "../pages/Home";
import logo from "../assets/logo.png";
import "./styles/AuthLayout.css";
import Blacklist from "../pages/BlackList";
import Business from "../pages/Business";
import UserList from "../pages/User";
import PendingAccounts from "../pages/PendingAccounts";
import TimeExtensionRequests from "../pages/TimeExtensionRequests ";
import UserDetail from "../pages/UserDetail";
import Package from "../pages/Package";
import Notifi from "../pages/Notifi";
import CameraSetting from "../pages/CameraSetting";
import { useDispatch, useSelector } from "react-redux";
import { logout, setInfo } from "../store/authSlice";
import apiService from "../services/apiService";
import DeviceDetail from "../pages/DeviceDetail";
const { Header, Sider, Content } = Layout;
const UPDATE_ADMIN_INFO = (id) => `/admin/${id}`;

const AuthLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const info = useSelector((state) => state.auth.info);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    if (e.key === "info") {
      setIsModalVisible(true);
      form.setFieldsValue(info);
    } else if (e.key === "logout") {
      dispatch(logout());
      navigate("/login");
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsEditing(false);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();

      // Format the data for the API call
      const payload = {
        user_name: info.user_name, // Assuming user_name remains unchanged
        password: info.password, // Assuming password remains unchanged
        name: values.name,
        email: values.email,
        phone: values.phone,
        web_link: values.web_link,
      };

      // Make the API call
      const response = await apiService.put(
        UPDATE_ADMIN_INFO(info.id),
        payload
      );
      console.log(response);
      dispatch(setInfo(response.data));
      // Close the modal and handle the state if needed
      setIsEditing(false);
      setIsModalVisible(false);
      console.log("User info updated successfully!");
    } catch (error) {
      console.error("Error updating user info:", error);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="info">Thông tin</Menu.Item>
      <Menu.Item key="logout">Thoát</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Layout className="Layout-container">
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            background: colorBgContainer,
          }}
        >
          <div className="demo-logo-vertical">
            <img
              src={logo}
              alt="Logo"
              style={{ width: "100%", padding: "10px", cursor: "pointer" }}
            />
          </div>

          <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
            <Menu.Item key="1" icon={<DashboardOutlined />}>
              <Link to="/">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<UserOutlined />}>
              <Link to="/customer">Khách hàng</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<UnorderedListOutlined />}>
              <Link to="/blacklist">Danh sách đen</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<BarcodeOutlined />}>
              <Link to="/business">Mã ngành</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<UserAddOutlined />}>
              <Link to="/pending_accounts">Tài khoản chờ duyệt</Link>
            </Menu.Item>
            <Menu.Item key="6" icon={<AuditOutlined />}>
              <Link to="/time_extension_requests">Gói chờ duyệt</Link>
            </Menu.Item>
            <Menu.Item key="7" icon={<DollarOutlined />}>
              <Link to="/packages">Gói</Link>
            </Menu.Item>
            <Menu.Item key="8" icon={<NotificationOutlined />}>
              <Link to="/notify">Thông báo</Link>
            </Menu.Item>
            <Menu.Item key="9" icon={<VideoCameraAddOutlined />}>
              <Link to="/cameraSetting">Camera</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <div className="header">
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <div className="avatar">
                <Dropdown overlay={menu} trigger={["click"]}>
                  <Avatar
                    style={{
                      backgroundColor: "#87d068",
                    }}
                    icon={<UserOutlined />}
                  />
                </Dropdown>
              </div>
            </div>
          </Header>
          <Content
            style={{
              margin: "24px 16px",
              padding: 24,
              minHeight: 280,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/blacklist" element={<Blacklist />} />
              <Route path="/business" element={<Business />} />
              <Route path="/customer" element={<UserList />} />
              <Route path="/pending_accounts" element={<PendingAccounts />} />
              <Route
                path="/time_extension_requests"
                element={<TimeExtensionRequests />}
              />
              <Route path="/packages" element={<Package />} />
              <Route path="/view/:user_id" element={<UserDetail />} />
              <Route
                path="/view/:user_id/:device_id"
                element={<DeviceDetail />}
              />
              <Route path="/notify" element={<Notifi />} />
              <Route path="/cameraSetting" element={<CameraSetting />} />
            </Routes>
          </Content>
        </Layout>
      </Layout>
      <Modal
        title="Thông tin của admin"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          isEditing ? (
            <>
              <Button key="save" type="primary" onClick={handleSave}>
                Save
              </Button>
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button key="edit" type="primary" onClick={handleEdit}>
                Edit
              </Button>
              <Button key="back" onClick={handleCancel}>
                Return
              </Button>
            </>
          ),
        ]}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={info}
          disabled={!isEditing}
        >
          <Form.Item name="name" label="Name">
            <Input />
          </Form.Item>
          <Form.Item name="phone" label="Phone">
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="web_link" label="Web Link">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AuthLayout;
