import React, { useEffect, useState } from "react";
import { Button, Space, Table, Modal, Form, Input, message } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import apiService from "../services/apiService"; // Adjust the path based on your project structure

// API endpoints
const LIST_PRODUCTS_URL = "/products/";
const ADD_PRODUCT_URL = "/product/";
const GET_TOTAL_ITEMS_PRODUCTS = "/admin/count?table=product";
const UPDATE_PRODUCT_URL = (id) => `/product/${id}`;
const Business = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 100, // Total number of records for pagination
    },
  });

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    try {
      const totalItems = await apiService.get(GET_TOTAL_ITEMS_PRODUCTS);

      const response = await apiService.get(LIST_PRODUCTS_URL, {
        params: {
          skip: (current - 1) * pageSize,
          limit: pageSize,
        },
      });
      console.log(response.data);
      setData(response.data.elements); // Assuming the response structure has an `items` array
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: totalItems.data.count, // Assuming the response has a `total` field for total items
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: sorter.order,
      sortField: sorter.field,
    });
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    console.log(record);
    editForm.setFieldsValue(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = async (stt) => {
    // Handle delete logic here
    // Assuming delete API and handling will be similar
    setData(data.filter((item) => item.stt !== stt));
  };

  const handleAdd = async () => {
    try {
      const values = await form.validateFields();

      const body = {
        typevn: values.category,
        typeen: values.categoryEnglish,
        code: values.code,
      };
      await apiService.post(ADD_PRODUCT_URL, body);
      message.success("Business category added successfully");
      setIsModalVisible(false);
      form.resetFields();
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to add business category");
    }
  };
  const handleUpdate = async () => {
    try {
      const values = await editForm.validateFields();

      await apiService.put(UPDATE_PRODUCT_URL(currentRecord.id), values);
      message.success("Business category updated successfully");
      setIsEditModalVisible(false);
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to update business category");
    }
  };
  // Define columns for the table
  const columns = [
    {
      title: "STT",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.stt - b.stt,
    },
    {
      title: "Ngành hàng",
      dataIndex: "typevn",
      key: "typevn",
    },
    {
      title: "Ngành hàng (English)",
      dataIndex: "typeen",
      key: "typeen",
    },
    {
      title: "Mã ngành",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.stt)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <h2>Business Categories</h2>
        <Button
          type="primary"
          icon={<FileAddOutlined />}
          onClick={() => setIsModalVisible(true)}
        >
          Add
        </Button>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.stt}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Add Business Category"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleAdd}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="category"
            label="Ngành hàng"
            rules={[
              { required: true, message: "Please input the category name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="categoryEnglish"
            label="Ngành hàng (English)"
            rules={[
              {
                required: true,
                message: "Please input the category name in English!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="code"
            label="Mã ngành"
            rules={[
              { required: true, message: "Please input the category code!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Edit Business Category"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={handleUpdate}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="typevn"
            label="Ngành hàng"
            rules={[
              { required: true, message: "Please input the category name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="typeen"
            label="Ngành hàng (English)"
            rules={[
              {
                required: true,
                message: "Please input the category name in English!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="code"
            label="Mã ngành"
            rules={[
              { required: true, message: "Please input the category code!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Business;
