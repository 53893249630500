import { createSlice } from "@reduxjs/toolkit";

const deviceSlice = createSlice({
  name: "device",
  initialState: {
    user_name: "",
    device_id: "",
  },
  reducers: {
    setUserName: (state, action) => {
      state.user_name = action.payload;
    },
    setDeviceId: (state, action) => {
      state.device_id = action.payload;
    },
  },
});

export const { setUserName, setDeviceId } = deviceSlice.actions;

const deviceReducer = deviceSlice.reducer;
export default deviceReducer;
