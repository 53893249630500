import React, { useState, useEffect } from "react";
import { Table, Button, Space, message, Tag, Select } from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import apiService from "../services/apiService";
import { formatTimestamp } from "../utils/formatTimes";
import Modal from "antd/es/modal/Modal";
import "./styles/TimeExtensionRequests .css";
const LIST_PENDING_PACKAGE = "/account/get-all-codes/pending";
const LIST_APPROVED_PACKAGE = "/account/get-all-codes/approve";
const LIST_DECLINED_PACKAGE = "/account/get-all-codes/decline";
const APPROVE_URL = "/account/admin-approve/";
const DECLINE_URL = "/account/update";

const TimeExtensionRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 100, // You can set an estimated total or handle it dynamically
    },
  });
  const [view, setView] = useState("pending");

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, view]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: sorter.order,
      sortField: sorter.field,
    });
  };

  const fetchData = async (current, pageSize) => {
    setLoading(true);
    const url =
      view === "approved"
        ? LIST_APPROVED_PACKAGE
        : view === "declined"
        ? LIST_DECLINED_PACKAGE
        : LIST_PENDING_PACKAGE;
    try {
      const response = await apiService.get(url, {
        params: {
          skip: (current - 1) * pageSize,
          limit: pageSize,
        },
      });

      console.log(response.data);

      const formattedData = response.data.map((item) => ({
        key: item.activation_code.id,
        activatecode_id: item.activation_code.id,
        device_id: item.activation_code.device_id,
        username: item.user.username,
        email: item.user.email,
        phonenumber: item.user.phonenumber,
        package_description: item.package.description,

        code: item.activation_code.code,
        expired_code: item.activation_code.expire_code_at,
        end_package_at: item.activation_code.end_package_at,
        user: item.user,
        package: item.package,
        is_expire: item.is_expire,
        is_send_mail: item.activation_code.is_send_mail,
      }));

      setData(formattedData);

      // Dynamically update total if needed
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: prev.pagination.total + formattedData.length, // Update total dynamically if needed
        },
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleApprove = async (record) => {
    const body = {
      id: record.activatecode_id,
      user_id: record.user.id,
      device_id: record.device_id,
      package_id: record.package.id,
      code: record.code,
    };
    console.log(body);
    Modal.confirm({
      title: "Xác nhận gửi code",
      content: `Bạn có chắc chắn muốn gửi code tới ${record.user.fullname}?`,
      onOk: async () => {
        try {
          await apiService.post(APPROVE_URL, body);
          message.success("Gửi thành công");
          fetchData(
            tableParams.pagination.current,
            tableParams.pagination.pageSize
          );
        } catch (error) {
          message.error("Failed to approve account");
        }
      },
    });
  };

  const handleDecline = async (record) => {
    const data = {
      trans_id: record.activatecode_id,
      user_id: record.user.id,
      package_id: record.package.id,
      code: record.code,
      status: "decline",
      is_send_mail: record.is_send_mail,
    };
    console.log(record);
    Modal.confirm({
      title: "Xác nhận từ chối",
      content: `Bạn có chắc chắn từ chối yêu cầu của ${record.user.fullname}?`,
      onOk: async () => {
        try {
          await apiService.put(DECLINE_URL, data);
          message.success("Từ chối thành công");
          fetchData(
            tableParams.pagination.current,
            tableParams.pagination.pageSize
          );
        } catch (error) {
          message.error("Failed to approve account");
        }
      },
    });
  };

  const expandedRowRender = (record) => {
    const userColumns = [
      {
        title: "Full Name",
        dataIndex: "fullname",
        key: "fullname",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Address",
        dataIndex: "address",
        key: "address",
      },
      {
        title: "Phone Number",
        dataIndex: "phonenumber",
        key: "phonenumber",
      },

      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        render: (created_at) => formatTimestamp(created_at),
      },
    ];

    const packageColumns = [
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
      },
      {
        title: "Duration",
        dataIndex: "duration",
        key: "duration",
      },
      {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
      },
      {
        title: "Details",
        dataIndex: "details",
        key: "details",
        render: (details) => details.join(", "),
      },
    ];

    return (
      <>
        <h4>User Information</h4>
        <Table
          columns={userColumns}
          dataSource={[record.user]}
          pagination={false}
          rowKey="id"
        />
        <h4>Package Information</h4>
        <Table
          columns={packageColumns}
          dataSource={[record.package]}
          pagination={false}
          rowKey="id"
        />
      </>
    );
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: "10%",
    },

    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "10%",
    },
    {
      title: "Machine_id",
      dataIndex: "device_id",
      key: "device_id",
      width: "10%",
      ellipsis: true,
    },
    {
      title: "Expired Code",
      dataIndex: "expired_code",
      key: "expired_code",
      render: (expired_code) => formatTimestamp(expired_code),
    },
    {
      title: "Code Hết Hạn",
      dataIndex: "is_expire",
      key: "is_expire",
      width: "10%",
      render: (is_expire) => (
        <Tag
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          color={!is_expire ? "green" : "red"}
        >
          {!is_expire ? "Còn hạn" : "Hết hạn"}
        </Tag>
      ),
    },
    {
      title: "Gửi code",
      dataIndex: "is_send_mail",
      key: "is_send_mail",
      width: "10%",
      render: (is_send_mail) => (
        <Tag
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          color={is_send_mail ? "green" : "red"}
        >
          {is_send_mail ? "Đã gửi" : "Chưa gửi"}
        </Tag>
      ),
    },
    ...(view !== "approved" && view !== "declined"
      ? [
          {
            title: "Action",
            key: "action",
            render: (_, record) => (
              <Space size="middle">
                <Button
                  icon={<CheckOutlined />}
                  type="primary"
                  onClick={() => handleApprove(record)}
                  disabled={record.is_send_mail}
                >
                  Gửi code
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  danger
                  onClick={() => handleDecline(record)}
                >
                  Decline
                </Button>
              </Space>
            ),
          },
        ]
      : []),
  ];

  const customExpandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <MinusOutlined onClick={(e) => onExpand(record, e)} />
    ) : (
      <PlusOutlined onClick={(e) => onExpand(record, e)} />
    );

  return (
    <>
      <Select
        value={view}
        onChange={(value) => setView(value)}
        style={{ marginBottom: 16 }}
      >
        <Select.Option value="pending">Pending</Select.Option>
        <Select.Option value="approved">Approved</Select.Option>
        <Select.Option value="declined">Declined</Select.Option>
      </Select>
      <Table
        className="Table-container"
        columns={columns}
        expandable={{
          expandedRowRender,
          expandIcon: customExpandIcon,
        }}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
    </>
  );
};

export default TimeExtensionRequests;
