import React, { useEffect, useState } from "react";
import {
  Card,
  List,
  Row,
  Col,
  Statistic,
  Tag,
  Table,
  message,
  Select,
} from "antd";
import { Bar, Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import apiService from "../services/apiService";
import { useSelector } from "react-redux";
import { checkDevice } from "../utils/checkDevice";
import { useParams } from "react-router-dom";
const GET_TOTAL_USER = "/statistic/count-user";
const GET_TOTAL_ONLINE = "/statistic/count-online";
const DeviceDetail = () => {
  const { user_id, device_id } = useParams();
  const [selectedDays, setSelectedDays] = useState(5);
  const [statics, setstatics] = useState([
    {
      type: "dong-goi",
      count: 0,
      durarion: 0,
    },
    {
      type: "tra-hang",
      count: 0,
      durarion: 0,
    },
  ]);
  const [history, setHistory] = useState([]);
  const deviceInfo = useSelector((state) => state.device);
  useEffect(() => {
    // Mock data for demonstration
    const fetchData = async () => {
      try {
        const response = await apiService.get(
          `/statistic/count-transaction/${user_id}/${device_id}/-1`
        );
        // Assuming the response structure has an `items` array

        const updatedStatics = statics.map((item) => {
          const found = response.data.find((res) => res.type === item.type);
          return found ? found : item;
        });

        setstatics(updatedStatics);
      } catch (error) {
        message.error("Failed to fetch data");
      }
      try {
        const response = await apiService.get(
          `/statistic/count-transaction/${user_id}/${device_id}/${selectedDays}`
        );
        console.log(response.data);
        setHistory(response.data);
        // Assuming the response structure has an `items` array
      } catch (error) {
        message.error("Failed to fetch data");
      }
    };
    fetchData();
  }, []);
  const handleDaysChange = async (value) => {
    console.log(value);
    try {
      const response = await apiService.get(
        `/statistic/count-transaction/${user_id}/${device_id}/${value}`
      );
      console.log(response.data);
      setHistory(response.data);
      // Assuming the response structure has an `items` array
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setSelectedDays(value);
  };
  const historyColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Total Videos",
      dataIndex: "totalCount",
      key: "totalCount",
    },
    {
      title: "Total Duration (seconds)",
      dataIndex: "totalDuration",
      key: "totalDuration",
    },
  ];

  const expandedRowRender = (record) => {
    const expandedColumns = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "count",
      },
      {
        title: "Duration (seconds)",
        dataIndex: "duration",
        key: "duration",
      },
    ];

    return (
      <Table
        columns={expandedColumns}
        dataSource={record.details}
        pagination={false}
        rowKey="type"
      />
    );
  };

  const getTableData = () => {
    return Object.entries(history).map(([date, transactions]) => {
      const totalCount = transactions.reduce(
        (sum, item) => sum + item.count,
        0
      );
      const totalDuration = transactions.reduce(
        (sum, item) => sum + item.duration,
        0
      );

      return {
        date,
        totalCount,
        totalDuration,
        details: transactions,
      };
    });
  };
  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
    {
      title: "Duration (seconds)",
      dataIndex: "durarion",
      key: "durarion",
    },
  ];
  return (
    <div
      style={{
        height: "100%",
        padding: "24px",
        background: "#f0f2f5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>
        {deviceInfo.user_name}/{checkDevice(deviceInfo.device_id)}
      </h2>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Card
              bordered={false}
              style={{ background: "#ffffff", height: "100%" }}
            >
              <Statistic
                title="Video đã quay"
                value={statics[0].count + statics[1].count}
              />
            </Card>
          </Col>

          <Col span={12}>
            <Card
              bordered={false}
              style={{ background: "#ffffff", height: "100%" }}
            >
              <Statistic
                title="Tổng thời gian quay"
                value={`${statics[0].durarion + statics[1].durarion} giây`}
              />
            </Card>
          </Col>
          <Col span={24}>
            <Card bordered={false} style={{ background: "#ffffff" }}>
              <Table
                columns={columns}
                dataSource={statics}
                rowKey="type"
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
          <Col span={24}>
            <Card bordered={false} style={{ background: "#ffffff" }}>
              <Select
                style={{ width: "100%" }}
                value={selectedDays}
                onChange={handleDaysChange}
              >
                <Select.Option value={5}>5 Days</Select.Option>
                <Select.Option value={10}>10 Days</Select.Option>
                <Select.Option value={15}>15 Days</Select.Option>
                <Select.Option value={30}>30 Days</Select.Option>
              </Select>
              <Table
                columns={historyColumns}
                dataSource={getTableData()}
                expandedRowRender={expandedRowRender}
                rowKey="date"
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DeviceDetail;
