import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Table,
  Modal,
  Form,
  Input,
  message,
  Radio,
  Select,
  Tag,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import apiService from "../services/apiService"; // Adjust the path based on your project structure

// API endpoints
const LIST_NOTIFY_URL = (status) => `/notify/all/${status}`;
const ADD_NOTIFY_URL = "/notify/create-notify";
const UPDATE_NOTIFY_URL = (id) => `/notify/update-notify/${id}`;

const Notifi = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [view, setView] = useState("all");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0, // Total number of records for pagination
    },
  });

  const fetchData = async (current, pageSize) => {
    setLoading(true);

    try {
      const response = await apiService.get(LIST_NOTIFY_URL(view), {
        params: {
          skip: (current - 1) * pageSize,
          limit: pageSize,
        },
      });
      console.log(response);
      setData(response.data.elements); // Assuming the response structure has an `items` array
      setTableParams((prev) => ({
        ...prev,
        pagination: {
          ...prev.pagination,
          total: response.data.count, // Assuming the response has a `total` field for total items
        },
      }));
    } catch (error) {
      message.error("Failed to fetch data");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData(tableParams.pagination.current, tableParams.pagination.pageSize);
  }, [tableParams.pagination.current, tableParams.pagination.pageSize, view]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      sortOrder: sorter.order,
      sortField: sorter.field,
    });
  };

  const handleEdit = (record) => {
    setCurrentRecord(record);
    editForm.setFieldsValue(record);
    setIsEditModalVisible(true);
  };

  const handleDelete = async (id) => {
    try {
      //await apiService.delete(UPDATE_PACKAGE_URL(id));
      message.success("Notification deleted successfully");
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to delete notification");
    }
  };

  const handleAdd = async () => {
    try {
      const values = await form.validateFields();
      const body = {
        title: values.title,
        description: values.description,
        status: values.status,
      };
      await apiService.post(ADD_NOTIFY_URL, body);
      message.success("Notification added successfully");
      setIsModalVisible(false);
      form.resetFields();
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to add notification");
    }
  };

  const handleUpdate = async () => {
    try {
      const values = await editForm.validateFields();
      await apiService.put(UPDATE_NOTIFY_URL(currentRecord.id), null, {
        params: {
          title: values.title,
          description: values.description,
          status: values.status,
        },
      });
      message.success("Notification updated successfully");
      setIsEditModalVisible(false);
      fetchData(
        tableParams.pagination.current,
        tableParams.pagination.pageSize
      );
    } catch (error) {
      message.error("Failed to update notification");
    }
  };

  // Define columns for the table
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      width: "10%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "10%",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (status) => (
        <Tag
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          color={status === "send" ? "green" : "red"}
        >
          {status === "send" ? "Đã gửi" : "Chưa gửi"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => handleDelete(record.id)}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <div>
          <h2>Notifications</h2>
          <Select
            value={view}
            onChange={(value) => setView(value)}
            style={{ marginBottom: 16 }}
          >
            <Select.Option value="all">Tất cả</Select.Option>
            <Select.Option value="send">Đã Gửi</Select.Option>
            <Select.Option value="unsend">Chưa Gửi</Select.Option>
          </Select>
        </div>
        <div>
          <Button
            type="primary"
            icon={<FileAddOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Add
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Thêm thông báo"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleAdd}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="title"
            label="Tiêu Đề"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả"
            rules={[
              {
                required: true,
                message: "Please input description",
              },
            ]}
          >
            <Input.TextArea showCount maxLength={100} rows={4} />
          </Form.Item>
          <Form.Item
            name="status"
            label="Trạng thái"
            rules={[{ required: true, message: "Please select the status!" }]}
          >
            <Radio.Group>
              <Radio value="unsend">Chưa gửi</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Chỉnh sửa thông báo"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        onOk={handleUpdate}
      >
        <Form form={editForm} layout="vertical">
          <Form.Item
            name="title"
            label="Tiêu Đề"
            rules={[{ required: true, message: "Please input the title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Mô tả"
            rules={[
              {
                required: true,
                message: "Please input description",
              },
            ]}
          >
            <Input.TextArea showCount maxLength={100} rows={4} />
          </Form.Item>
          <Form.Item
            name="status"
            label="Trạng thái"
            rules={[{ required: true, message: "Please select the status!" }]}
          >
            <Radio.Group>
              <Radio value="send">Gửi thông báo</Radio>
              <Radio value="unsend">Chưa gửi</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Notifi;
